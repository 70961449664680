module.exports = [{
      plugin: require('/mnt/c/Users/admin/Projects/bergwerk/aatodd.com/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/mnt/c/Users/admin/Projects/bergwerk/aatodd.com/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/mnt/c/Users/admin/Projects/bergwerk/aatodd.com/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-131576285-1"},
    },{
      plugin: require('/mnt/c/Users/admin/Projects/bergwerk/aatodd.com/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
